@tailwind base;
@tailwind components;
@tailwind utilities;
.header {
  height: 65px;
  position: relative;
}

.header div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 65px;
  transform: translate(100%, 0);
}

.header div img {
  height: 60px;
}

.header .ticker-wrapper__first-half,
.header .ticker-wrapper__second-half {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  animation: ticker 30s infinite linear forwards;
}

.header .ticker-wrapper__second-half {
  animation: duration ticker duration/2 infinite linear forwards;
}

@keyframes ticker {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-25%, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}
